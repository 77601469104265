exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicativos-mindhub-js": () => import("./../../../src/pages/aplicativos/mindhub.js" /* webpackChunkName: "component---src-pages-aplicativos-mindhub-js" */),
  "component---src-pages-aplicativos-nfx-js": () => import("./../../../src/pages/aplicativos/nfx.js" /* webpackChunkName: "component---src-pages-aplicativos-nfx-js" */),
  "component---src-pages-aplicativos-orackle-js": () => import("./../../../src/pages/aplicativos/orackle.js" /* webpackChunkName: "component---src-pages-aplicativos-orackle-js" */),
  "component---src-pages-aplicativos-relic-js": () => import("./../../../src/pages/aplicativos/relic.js" /* webpackChunkName: "component---src-pages-aplicativos-relic-js" */),
  "component---src-pages-aplicativos-sense-js": () => import("./../../../src/pages/aplicativos/sense.js" /* webpackChunkName: "component---src-pages-aplicativos-sense-js" */),
  "component---src-pages-aplicativos-shift-js": () => import("./../../../src/pages/aplicativos/shift.js" /* webpackChunkName: "component---src-pages-aplicativos-shift-js" */),
  "component---src-pages-aplicativos-titan-js": () => import("./../../../src/pages/aplicativos/titan.js" /* webpackChunkName: "component---src-pages-aplicativos-titan-js" */),
  "component---src-pages-aplicativos-wow-js": () => import("./../../../src/pages/aplicativos/wow.js" /* webpackChunkName: "component---src-pages-aplicativos-wow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-basicos-js": () => import("./../../../src/pages/marketing/basicos.js" /* webpackChunkName: "component---src-pages-marketing-basicos-js" */),
  "component---src-pages-marketing-index-js": () => import("./../../../src/pages/marketing/index.js" /* webpackChunkName: "component---src-pages-marketing-index-js" */),
  "component---src-pages-marketing-vuelvete-un-profesional-js": () => import("./../../../src/pages/marketing/vuelvete-un-profesional.js" /* webpackChunkName: "component---src-pages-marketing-vuelvete-un-profesional-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-trading-binarias-js": () => import("./../../../src/pages/trading/binarias.js" /* webpackChunkName: "component---src-pages-trading-binarias-js" */),
  "component---src-pages-trading-criptos-js": () => import("./../../../src/pages/trading/criptos.js" /* webpackChunkName: "component---src-pages-trading-criptos-js" */),
  "component---src-pages-trading-forex-js": () => import("./../../../src/pages/trading/forex.js" /* webpackChunkName: "component---src-pages-trading-forex-js" */),
  "component---src-pages-trading-index-js": () => import("./../../../src/pages/trading/index.js" /* webpackChunkName: "component---src-pages-trading-index-js" */),
  "component---src-pages-trading-indices-sinteticos-js": () => import("./../../../src/pages/trading/indices-sinteticos.js" /* webpackChunkName: "component---src-pages-trading-indices-sinteticos-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

